<template>
  <v-col cols="12">
    <v-col id="container" cols="12">
      <img v-if="bonus" alt="Logo bonus" class="mr-5" src="@/assets/bonus.png"/>
      <div>
        <template v-if="bonus">
          <div id="price" class="text-decoration-line-through">PRIX : {{ amount }}&nbsp;€</div>
          <div id="bonus" :style="`background-color: ${environment.theme.bannerBackgroundColor}`">
            PRIX&nbsp;TOTAL&nbsp;:
            {{ Math.round((amount - bonus) * 100) / 100 }}&nbsp;€
          </div>
        </template>
        <div v-else id="price">PRIX : {{ amount }}&nbsp;€</div>
      </div>
    </v-col>
    <v-col v-if="displayBottomLine" md="4" offset-md="4">
      <div id="line"/>
    </v-col>
  </v-col>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Price",
  props: {
    amount: {
      type: Number,
      required: true
    },
    bonus: {
      type: Number
    },
    displayBottomLine: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('environment-app', ['environment'])
  }
}
</script>

<style scoped>
* {
  font-size: 1.2rem;
  text-align: center;
}

#container {
  display: flex;
  justify-content: center;
}

#price, #bonus {
  padding: 0 10px;
}

#bonus {
  min-width: 125px;
}

img {
  max-width: 70px;
  object-fit: contain;
}

#line {
  border-top: solid 1px;
}
</style>

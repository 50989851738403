<template>
  <div style="padding:2rem">
    <v-container>
      <v-form
        ref="form"
        lazy-validation
      >
        <h2 class="mt10 pt10">REMPLACER UNE PRESSION / SCRATCH</h2>
        <br/><br/>
        <v-row>
          <v-col cols="12" sm="12">
            <table border="0" class="dimensions">
              <tr>
                <td>Quantité à réparer</td>
                <td style="padding-left:10px;">
                  <Vminusplusfield
                    :index="0"
                    :max="10"
                    :min="1"
                    :price="price"
                    :value="totalQty"
                    @input="getInfos"
                  />
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="spacer"></div>
            <table>
              <tr>
                <td colspan="2" style="padding: 0px 0px 0px 5px;">
                  <v-checkbox
                    v-model="isTraversant"
                    label="Traversant ?"
                    @click="getPrice()"
                  >
                    <template v-slot:label>
                      <div>Traversant ?</div>
                    </template>
                  </v-checkbox>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="padding: 0px 0px 0px 5px;">
                  <p>
                    Traversant : Mon « attache » est visible de l’autre coté / dessous.<br/>
                    Non-Traversant : Mon « attache » n’est pas visible de l’autre coté.
                  </p>
                </td>
              </tr>
            </table>
          </v-col>
          <v-col cols="12" sm="12">
            <br/><br/>
            <h6>EMPLACEMENT</h6>
            <v-textarea
              v-model="emplacement"

              name="input-7-4"
              outlined
              placeholder="Indiquez l'emplacement"
              rows="3"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <price :amount="price" :display-bottom-line="totalQty > 0"/>
          </v-col>
        </v-row>
        <confirm-flow
          v-if="totalQty > 0 && !this.flux.indexEditProduit"
          @finalize="noarticle"
          @new-repair="onSelectNewRepair"
          @new-article="noarticle(false)"
        />
        <v-row v-else-if="this.flux.indexEditProduit">
          <v-col v-if="error" cols="12">
            <div class="text-center" style="color:#FF0000">Vous devez sélectionner le nombre de pressions à
              réparer
            </div>
          </v-col>
          <v-col class="text-center" cols="12">
            <div class="btn btn-1 text-center btn-size" @click="noarticle">MODIFIER</div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <span style="display:none">{{ flux.actionId }}</span>
  </div>
</template>
<script>
// import Modal from '../Modal.vue';
import {mapMutations, mapState} from 'vuex'
import dataPrice from '../../assets/data/price/bouton_pression.json';
import Vminusplusfield from '../interface/PlusMinusField.vue'
import Price from "@/components/shared/Price.vue";
import ConfirmFlow from "@/components/shared/ConfirmFlow.vue";

let liste_reparations = []

export default {
  data() {
    return {
      emplacement: null,
      isTraversant: false,
      price: 0,
      oneUpdate: false,
      promotion: null,
      tabPromotions: [],
      totalQty: 1,
      tmpPrice: 0,
      error: false

    }
  },
  created() {
    //Recup valeur champ si mode Edition
    this.tabPromotions = dataPrice[0][this.flux.level2].promotions
    if (this.flux.indexEditProduit !== null) {
      this.emplacement = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['emplacement']
      this.isTraversant = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['isTraversant']
      this.price = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['price']
      this.totalQty = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['qteFinal']
    }
    this.getPrice()
  },
  updated() {
    if (this.flux.componentName !== 'bouton') {
      return null;
    }
    this.tabPromotions = dataPrice[0][this.flux.level2].promotions
    //Recup valeur champ si mode Edition
    if (this.flux.level2 && this.flux.verrou === false && this.flux.indexEditProduit !== null) {
      this.setVerrou(true)
      this.emplacement = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['emplacement']
      this.isTraversant = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['isTraversant']
      this.price = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['price']
      this.totalQty = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['qteFinal']
    }
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products']),
    ...mapState('flux-app', ['flux']),
  },
  methods: {
    ...mapMutations('flux-app', ['setConstat', 'setcptProduct', 'setcptReparation', 'setVerrou', 'setLevel1', 'setLevel2', 'setLevel3', 'setComponentName']),
    ...mapMutations('cart-app', ['addProduct', 'getTotalCart']),
    editRepair(indexes) {
      this.$emit('edit', indexes)
    },
    evenement(value) {
      if (value == 'scroll3') {
        this.$scrollTo('#screen3')
      }
      if (value == 'scroll1') {
        this.$scrollTo('#screen1')
      }
    },
    noarticle(showCart = true) {
      if (this.price <= 0) {
        this.error = true;
      } else if (this.$refs.form.validate()) {
        if (this.flux.indexEditProduit !== null) { //Mode Edit
          this.editProduct();
          this.setLevel1(null)
          this.setLevel2(null)
          this.setLevel3(null)
          this.setComponentName(null)
        } else {
          this.storeProduct()
          this.setcptProduct(this.flux.cptProduct + 1)
          liste_reparations = []
          this.setcptReparation(0)
        }

        this.$emit('evenement', 'scroll1')

        if (showCart) {
          this.showModal('cart')
        }
      }
    },
    onSelectNewRepair() {
      this.storeProduct()
      this.$emit('evenement', 'scroll3')
    },
    editProduct() {
      liste_reparations = this.products[this.flux.indexEditProduit].reparations
      //On check l'historique des réparations
      let index = liste_reparations.findIndex((obj => obj.id == (this.flux.indexEditReparation + 1)));
      if (index > -1) {
        let reparation = {
          id: liste_reparations[index].id,
          type: 'pression',
          componentName: 'pression',
          emplacement: this.emplacement,
          isTraversant: this.isTraversant,
          qteFinal: this.totalQty,
          qte: 1,
          price: this.price
        }

        this.products[this.flux.indexEditProduit].reparations.splice(index, 1, reparation)

        let data = {
          idLevel1: this.products[this.flux.indexEditProduit].idLevel1,
          level1: this.products[this.flux.indexEditProduit].level1,
          level2: this.products[this.flux.indexEditProduit].level2,
          level3: this.products[this.flux.indexEditProduit].level3,
          isDouble: this.products[this.flux.indexEditProduit].isDouble,
          id: this.products[this.flux.indexEditProduit].id,
          name: this.products[this.flux.indexEditProduit].name,
          famille: this.products[this.flux.indexEditProduit].famille,
          reparations: liste_reparations
        }


        this.addProduct(data)
        this.getTotalCart(this.products)
        this.$emit('evenement', 'scroll3')

      }
    },
    storeProduct() {
      if (this.price <= 0) {
        this.error = true;
      } else if (this.$refs.form.validate()) {
        this.setcptReparation(this.flux.cptReparation + 1)

        if (this.flux.cptProduct < 1) {
          this.setcptProduct(this.flux.cptProduct + 1)
        }

        //On check l'historique des réparations
        liste_reparations = []
        let index = this.products.findIndex((obj => obj.id == this.flux.cptProduct));
        if (index > -1) {
          liste_reparations = this.products[index].reparations
        }

        let reparation = {
          id: this.flux.cptReparation,
          type: this.flux.level3,
          componentName: 'pression',
          emplacement: this.emplacement,
          isTraversant: this.isTraversant,
          qteFinal: this.totalQty,
          qte: 1,
          price: this.price
        }

        liste_reparations.push(reparation)

        let data = {
          idLevel1: this.flux.idLevel1,
          level1: this.flux.level1,
          level2: this.flux.level2,
          level3: this.flux.level3,
          isDouble: this.flux.isDouble,
          id: this.flux.cptProduct,
          name: this.flux.level2,
          famille: this.flux.level1,
          reparations: liste_reparations
        }
        this.addProduct(data)
        this.getTotalCart(this.products)
        this.setLevel3(null)
        this.setComponentName(null)
      }
    },
    showModal(constat) {
      this.setConstat(constat)
      this.$emit('showmodal', this.flux.level2)
    },
    closeModal() {
      this.isModalVisible = false;
    },
    getInfos(value) {
      this.totalQty = value[1]
      this.promotion = 0
      this.price = dataPrice[0][this.flux.level2]['pression']['price'] * this.totalQty
      for (let j = 0; j < this.tabPromotions.length; j++) {
        if (this.totalQty > 1 && this.totalQty < this.tabPromotions[j].qtemax) {
          this.promotion = this.tabPromotions[j].remise
          break;
        }
      }
      this.getPrice()
    },
    getPrice() {
      this.price = dataPrice[0][this.flux.level2]['pression']['price']
      if (this.isTraversant === true) {
        this.price = (this.price + dataPrice[0][this.flux.level2]['pression']['is_traversant']) * this.totalQty
      } else {
        this.price = dataPrice[0][this.flux.level2]['pression']['price'] * this.totalQty
      }

      if (this.flux.isDouble) {
        this.price = this.price * 1.2
      }

      this.tmpPrice = this.price * (1 - (this.promotion / 100))
      this.price = Math.round(this.tmpPrice * 100) / 100
      this.error = false;
    }
  },
  components: {
    ConfirmFlow,
    Price,
    Vminusplusfield
  }
}
</script>
<style scoped>
table {
  max-width: 390px;
}

table td.picto, table td.picto img {
  width: 100px;
}

table td.picto {
  padding: 20px 20px 20px 0;
}

.btn {
  background-color: #000;
  width: 120px;
  padding: 10px;
  color: #fff
}

.grey {
  background-color: #F8F8F8 !important;
}
</style>

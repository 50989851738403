<template>
  <div class="minusplusnumber">
      <div
          class="mpbtn minus"
          :class="{ disabled: min !== undefined && value <= min }"
          v-on:click="mpminus()"
      >
          -
      </div>
      <div id="field_container">
          <input type="number" v-model="newValue" disabled />
      </div>
      <div
          class="mpbtn plus"
          :class="{ disabled: max !== undefined && value >= max }"
          v-on:click="mpplus()"
      >
          +
      </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: 0,
      type: Number
    },
    price: {
      default: 0,
      type: Number
    },
    index: {
      default: 0,
      type: Number
    },
    indexR: {
      default: -1,
      type: Number
    },
    min: {
      default: 0,
      type: Number
    },
    max: {
      default: undefined,
      type: Number
    }
  },

  data () {
    return {
      newValue: 0,
      priceTotal: 0,
    }
  },

  methods: {
    getNotificationClass (notification) {
      return `alert alert-${notification.type}`
    },
    mpplus: function () {
      if (this.max === undefined || (this.newValue < this.max)) {
        this.newValue = this.newValue + 1
        this.priceTotal = (this.newValue * this.$props.price)
        if(this.$props.indexR > -1) {
          this.$emit('input', [this.$props.index, this.newValue, this.priceTotal, this.$props.indexR])
        } else {
          this.$emit('input', [this.$props.index, this.newValue, this.priceTotal])
        }
      }
    },
    mpminus: function () {
      if ((this.newValue) > this.min) {
        this.newValue = this.newValue - 1
        this.priceTotal = (this.newValue * this.$props.price)
        if(this.$props.indexR > -1) {
          this.$emit('input', [this.$props.index, this.newValue, this.priceTotal, this.$props.indexR])
        } else {
          this.$emit('input', [this.$props.index, this.newValue, this.priceTotal])
        }
      }
    }
  },
  watch: {
    value: {
      handler: function (newVal) {
        this.newValue = newVal
      }
    }
  },
  created: function () {
    this.newValue = this.value
  }
}
</script>
<style lang="scss" scoped>
.minusplusnumber {
  border: 1px solid silver;
  border-radius: 5px;
  background-color: #FFF;
  margin: 0 5px 0 5px;
  display: inline-block;
  user-select: none;

  div {
    display: inline-block;
  }

  #field_container input {
    width: 50px;
    text-align: center;
    font-size: 15px;
    padding: 3px;
    border: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  .disabled {
    color: #b9b9b9;
  }

  .mpbtn {
    padding: 3px 10px 3px 10px;
    border-radius: 5px;
    text-align: center;

    &:not(.disabled) {
      &:hover {
        background-color: #DDD;
        cursor: pointer;
      }

      &:active {
        background-color: #c5c5c5;
      }
    }
  }
}
</style>
